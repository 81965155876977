<template>
  <div>
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-card>
          <div slot="header">
            <h3>
              {{$t('Edit User')}}
            </h3>
          </div>
          <div>
            <vs-row v-if="EditedUserData" type="flex" vs-justify="space-around" vs-align="center">
              <vs-col vs-w="2" vs-sm="12">
                <vs-row align="center">
                  <vs-col vs-w="12">
                    <vs-avatar :src="EditedUserData.avatar" size="large"/>
                  </vs-col>
                  <vs-col vs-w="12">
                    <vs-input class="w-full" type="file" @change="onFileChange" accept="image/*" />
            <span v-if="errors.image" class="text-danger text-sm valdation_error_avatar" v-show="errors.image">
              {{errors.image[0]}}
            </span>
                  </vs-col>
                </vs-row>
              </vs-col>
              <vs-col vs-w="8" vs-sm="12">
                <template>
                  <div class="vx-row mt-5">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('First Name')" v-model="EditedUserData.name"/>
                      <span v-if="errors.name" class="text-danger text-sm valdation_error_avatar" v-show="errors.name">
                        {{errors.name[0]}}
                      </span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('Last Name')" v-model="EditedUserData.last_name"/>
                      <span v-if="errors.last_name" class="text-danger text-sm valdation_error_avatar" v-show="errors.last_name">
                        {{errors.last_name[0]}}
                      </span>
                    </div>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('User Name')" v-model="EditedUserData.user_name"/>
                      <span v-if="errors.user_name" class="text-danger text-sm valdation_error_avatar" v-show="errors.user_name">
                        {{errors.user_name[0]}}
                      </span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('TC')" v-model="EditedUserData.tc"/>
                      <span v-if="errors.tc" class="text-danger text-sm valdation_error_avatar" v-show="errors.tc">
                        {{errors.tc[0]}}
                      </span>
                    </div>


                  </div>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input type="email" class="w-full" :label-placeholder="$t('Email')" v-model="EditedUserData.email"/>
                      <span v-if="errors.email" class="text-danger text-sm valdation_error_avatar" v-show="errors.email">
                        {{errors.email[0]}}
                      </span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input type="phone" class="w-full" :label-placeholder="$t('Phone')" v-model="EditedUserData.mobile"/>
                      <span v-if="errors.mobile" class="text-danger text-sm valdation_error_avatar" v-show="errors.mobile">
                        {{errors.mobile[0]}}
                      </span>
                    </div>
                  </div>
                  <div class="vx-row">


                    <div class="vx-col sm:w-1/2 w-full mb-2">
                          <span v-if="EditedUserData.languages" class="select-label-placeholder">
                        {{$t('Language')}}
                      </span>
                      <my-select                         :placeholder="$t('Select Language')"
                        class="selectExample mt-5"
                        style="width: 100%"
                        v-model="EditedUserData.languages">
                        <vs-select-item :key="index" :value="item" :text="$t(item)" v-for="(item,index) in LanguageOption"/>
                      </my-select>
                      <span v-if="errors.languages" class="text-danger text-sm valdation_error_avatar" v-show="errors.languages">
                        {{errors.languages[0]}}
                      </span>
                    </div>
                  </div>
                </template>
              </vs-col>
              <vs-col vs-w="12">
                <br/>
                <br/>
                <br/>
              </vs-col>
              <vs-col vs-w="11" align="end">
                <vs-button color="success" type="gradient" @click="saveUserInfo()" class="mr-3 mb-2">{{$t('Confirm')}}</vs-button>
                <vs-button color="warning" type="gradient" class="mb-2">{{$t('Reset')}}</vs-button>
              </vs-col>
            </vs-row>
          </div>

        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from '../../../axios'
import MySelect from '../../../components/MySelect'

/* eslint-disable */
export default {
  name: "UserAccount",
  components:{MySelect},
  data() {
    return {
      genders:['Male','Female','Other'],
      LanguageOption: [ 'English', 'Turkish'],
      EditedUserData:{},
      errors: {}
    }
  },
  methods:{
    onFileChange(e) {
        this.file = e.target.files[0];
        this.EditedUserData.avatar = URL.createObjectURL(this.file);
      },
    getData(){
      this.$store.dispatch("userAccount/getUserInfo")
    },
    saveUserInfo(){
     this.errors = []
        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }

        let formData = new FormData();
        formData.append('image', this.file||'');
        formData.append('name', this.EditedUserData.name);
        formData.append('id', this.EditedUserData.id);
        formData.append('last_name', this.EditedUserData.last_name);
        formData.append('user_name', this.EditedUserData.user_name);
        formData.append('email', this.EditedUserData.email);
        formData.append('mobile', this.EditedUserData.mobile);
        formData.append('tc', this.EditedUserData.tc);
        formData.append('status', this.EditedUserData.status);
        formData.append('languages', this.EditedUserData.languages);
        formData.append('role', this.EditedUserData.Role);
        formData.append('_method', 'PUT');

        axios.post('/users/'+this.EditedUserData.id, formData, config)
          .then(response=>{
            axios.get('authorize/my_account').then(response=>{
              localStorage.setItem('user', JSON.stringify(response.data.userInfo))
            this.$store.commit('UPDATE_USER_INFO',JSON.parse(localStorage.getItem('user')))
            })
          }).catch(error => {
              this.errors = error.response.data.errors
          })

    }
  },
  watch:{
    userInfo(val){
      this.EditedUserData = {...val}
    }
  },
  computed:{
    userInfo(){
      return this.$store.getters["userAccount/userInfo"]
    }
  },
  created(){
    this.getData()
  }
}
</script>

<style scoped>

</style>
